
.like-dislike-conversation-buttons {

  display: flex;
  gap: 15px;

  .icon {
    font-size: 20px;
  }

  .selected{
    color: gray !important;
    fill: #8b9ed3;
    pointer-events: none;
    cursor: default;
  }

  .hidden{
    display: none;
  }
}

