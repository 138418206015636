.menu-content-wrapper {
  margin-top: 0 !important;;
  height: fit-content !important;
  background-color: transparent !important;
}

.menu-header {
  color: black !important;
  font-weight: 500 !important;
  font-size: 16px !important;
}

.custom-menu-item {
  margin-left: 18px !important;
}

.custom-submenu-as-list {
  .custom-submenu-item {
    font-size: 14px;
    line-height: 20px;
    padding-left: 48px !important;
  }

  .ant-menu-item.ant-menu-item-only-child {
    height: 36px !important;
  }

  .ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
    background-color: transparent;

    .custom-submenu-item {
      background: linear-gradient(90deg, #F530FF 0%, #1A60A6 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
}

.custom-submenu-as-list.ant-menu-submenu-open {
  .ant-menu.ant-menu-sub.ant-menu-inline {
    margin: 8px 0 !important;
  }
}

