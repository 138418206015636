@import '../../../../style/variables.less';

.conversation {
  overflow: hidden;

  .innerContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .content {
    max-width: 700px;
    width: 700px;
    height: 100%;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e9e1fc;
    padding-bottom: 5px;
  }

  .headerTitle {
    margin: 0;
    max-width: 350px;
  }

  .title {
    color: @text-color;
    font-size: 18px;
  }

  .subtitle {
    color: @text-color;
    font-size: 15px;
    font-weight: 400;
  }

  .actions-buttons-wrapper {
    display: flex;
    align-items: center;

    gap: 20px;
  }
}


