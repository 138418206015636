@import '../../style/variables.less';

* {
  box-sizing: border-box;
}
.public-chat-page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  align-self: flex-start;
  height: calc(100vh - 80px);
  margin-top: 72px;

  @supports (height: calc(100dvh - 80px)) {
    height: calc(100dvh - 80px);
  }
}

.chat-header {
  height: 72px;
  background: #ffffffa3;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100vw;
  padding-block: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.public-chat-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60vw;
  padding: 20px;
  height: 100%;
}

#chat-header-inner-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100vw;

  #logo-btn {
    width: 113px;
    height: 32px;
    padding: 0;
  }
}

.copy-url-button {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.copy-url-button:hover,
.copy-url-button:hover .anticon,
.copy-url-button:hover .button-text {
  color: @message-color;
}

.copy-url-button:not(:hover),
.anticon .button-text {
  color: @text-color;
}

@media screen and (max-width: 853px) {
  .public-chat-container {
    width: 100vw;
    .copy-url-button {
      width: 90vw;
    }
  }

  #transparent-button {
    width: 166px;
    height: 44px;
  }

  #chat-header-inner-container {
    padding-inline: 20px;
    min-width: 100vw;

    #logo-btn {
      width: 73px;
      height: 21px;
    }
  }
}

