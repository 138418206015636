#buttons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  height: fit-content;
  width: 100%;
  padding-inline: 37px;
  .buttons-group {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    height: fit-content;
  }
  .button {
    height: 240px !important;
    width: 335px;
    box-shadow: 0px 3px 20px 1px #00000008;
    background: #ffffff73;
    border: 0px;
    border-radius: 20px;
    padding: 30px;
    display: flex;
    flex-direction: row;

    .button-text-wrapper { padding-left: 20px; }
  }
  .button:hover {
    background: #ffffff;
  }
  p {
    text-align: left;
    white-space: pre-wrap;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    margin-bottom: 0px;
    width: 100%;
  }
  h3 {
    text-align: left;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0;
    color: #0a2540;
    margin-top: 0;
  }
  a {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
}

.left-header-item {
  .ai-helper-header h1 {
    margin-bottom: 0;
  }
  .ai-helper-header p {
    width: 100%;
  }
}

@media screen and (max-width: 763px) {
  #buttons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .button {
      height: fit-content;
      width: 335px;
    }
  }
}

