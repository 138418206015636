
.drag-and-drop {
  width: clamp(200px, 800px, 90%);
  height: 150px;
  border-radius: 5px;
  border: 2px dashed #999;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit-button {
  max-width: 250px;
}


.file-icon {
  font-size: 50px;
  color: green;
}

.file-text {
  margin-top: 5px;
  width: 150px;
  line-height: 1.1;
}

.delete-file-icon{
  font-size: 17px;
  color: red;
}

