.agent-list {
  padding: 24px 0 !important;
  padding-left: 30px !important;

  ul {
    display: flex;
    flex-direction: column;
    gap: 19px
  }
}

.agent-item {
  color: black;
  font-size: 14px !important;
  line-height: 140%;
  font-weight: 500;
  padding: 0 0 0 18px !important;
}

.selected-agent {
  background: linear-gradient(90deg, #F530FF 0%, #1A60A6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.conversation-list {
  ul {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
}

.conversation-item {
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  padding-inline: 8px !important;
  margin: 0 10px !important;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(249, 248, 251, 0.7);
    border-radius: 8px;
  }
}

.selected-conversation {
  background-color: rgba(249, 248, 251, 0.7);
  padding-inline: 8px !important;
  margin: 0 10px !important;
  border-radius: 8px !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  transition: all 0.3s ease;
}

