

.button{
  //width: 27px !important;
  //height: 27px !important;
  //border-radius: 25px !important;
  //box-shadow: 0px 3px 20px 1px #00000008;
  //border: 2px solid #0A2540 !important;
  //padding: 0 !important;
  background-color: transparent !important;
}



.icon{
  //margin-top: 4px;
  //font-size: 15px ;
  color: #070707 !important;
}

//.disconnect .microphone-icon{
//  color: #0A2540 !important;
//}

.connect {
  color: #0A2540 !important;
}

.disconnect {
  color: #d51313 !important;
}

