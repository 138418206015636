@import '../../../style/variables.less';

.chat-messages-list-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-bottom: 28px;
}

.chat-messages-list {
  overflow-y: scroll;
  height: 100%;
  padding: 0;
}

.no-messages-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-messages-title{
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: var(@text-color);
  margin-bottom: 150px;
}

