@import '../../../style/variables.less';

.conversation-text-feedback-history {


  .title {
    color: @text-color;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
  }

  .rating-icon {
    position: absolute;
    font-size: 18px;
    top: 50%;
    transform: translateY(-50%);
    left: -35px;
  }
}

