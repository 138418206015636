.integration-icon {
  display: flex;
  align-items: center;
  border-radius: 2px;
  background-color: #FFFFFF;
}

.integration-btn {
  background-color: #4285F4 !important;
  border-color: #4285F4 !important;
  color: #FFFFFF !important;
  padding-left: 2px !important;
  border-radius: 4px !important;
  display: flex !important;
  align-items: center !important;
}

