@import '../../../style/variables.less';

.conversation-text-feedback {
  border-bottom: 1px solid #e9e1fc;


  .feedback-header {
    display: flex;
    flex-direction: column;
    padding: 0;
    align-items: flex-start;

  }

  .title {
    font-size: 18px;
    margin: 15px 0;
    user-select: none;
    cursor: pointer;
    color: @text-color;
  }


  .submit-button {
    width: 125px !important;
    font-size: 14px !important;
    height: 40px !important;
    text-transform: none !important;
  }


  .rating-wrapper {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
  }

  .rating-icon {
    font-size: 24px ;

  }

  .selected {
  color: #4866ffcc;
  }
}

.feedback-form-open {
  padding-bottom: 15px !important;
}

